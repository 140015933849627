import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Booster, Contest, ContestParticipant, ContestResponse, DailySpinStatus } from 'models';

type ContestState = {
  contest: Contest | null;
  nextContest: Contest | null;
  previousContest: Contest | null;
  participation: ContestParticipant | null;
  selectedBooster: Booster | null;
  dailySpinStatus: DailySpinStatus;
};

const initialState: ContestState = {
  contest: null,
  nextContest: null,
  participation: null,
  previousContest: null,
  selectedBooster: null,
  dailySpinStatus: null,
};

export const contestSlice = createSlice({
  name: 'contest',
  initialState,
  reducers: {
    setContestResponse(state, action: PayloadAction<ContestResponse>) {
      state.contest = action.payload.currentContest;
      state.nextContest = action.payload.nextContest;
      state.previousContest = action.payload.previousContest;
    },
    setpreviousContest(state, action: PayloadAction<Contest | null>) {
      state.previousContest = action.payload;
    },
    setContest(state, action: PayloadAction<Contest | null>) {
      state.contest = action.payload;
    },
    setNextContest(state, action: PayloadAction<Contest | null>) {
      state.nextContest = action.payload;
    },
    setParticipation(state, action: PayloadAction<ContestParticipant | null>) {
      state.participation = action.payload;
    },
    setSelectedBooster(state, action: PayloadAction<Booster>) {
      state.selectedBooster = action.payload;
    },
    setDailySpinStatus(state, action: PayloadAction<DailySpinStatus>) {
      state.dailySpinStatus = action.payload;
    },
  },
  selectors: {
    selectpreviousContest: (state: ContestState) => state.previousContest,
    selectContest: (state: ContestState) => state.contest,
    selectNextContest: (state: ContestState) => state.nextContest,
    selectParticipation: (state: ContestState) => state.participation,
    selectSelectedBooster: (state: ContestState) => state.selectedBooster,
    selectDailySpinStatus: (state: ContestState) => state.dailySpinStatus,
  },
});

export default contestSlice.reducer;

export const {
  setContest,
  setContestResponse,
  setNextContest,
  setParticipation,
  setpreviousContest,
  setSelectedBooster,
  setDailySpinStatus,
} = contestSlice.actions;

export const {
  selectContest,
  selectNextContest,
  selectParticipation,
  selectpreviousContest,
  selectSelectedBooster,
  selectDailySpinStatus,
} = contestSlice.selectors;
