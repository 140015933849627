import dayjs from 'dayjs';
import { Contest } from 'models';
import { formatDuration } from 'utils/formatDuration';

export function getEventStatusString(contest: Contest) {
  const now = dayjs().tz('America/New_York');
  const locksAtNewYork = dayjs(contest.status === 'scheduled' ? contest.startsAt : contest.locksAt).tz(
    'America/New_York'
  );
  const locksDiff = locksAtNewYork.diff(now);
  const durationUntilLocks = dayjs.duration(locksDiff);

  if (contest.status === 'completed') {
    return 'results are in';
  } else if (contest.status === 'locked') {
    return 'results are pending';
  } else if (contest.status === 'active') {
    return `locks in ${formatDuration(durationUntilLocks)}`;
  } else if (contest.status === 'scheduled') {
    return `opens in ${formatDuration(durationUntilLocks)}`;
  }

  return 'unknown status';
}
