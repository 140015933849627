import { FC } from 'react';

import { Box, BoxProps, Flex, Img } from '@chakra-ui/react';
import { Card, ContestParticipant, ContestStatus } from 'models';

import Spinner from 'components/Spinner';

import SquadCutCards from './ui/SquadCutCards';
import SquadDeck from './ui/SquadDeck';
import SquadDetailedInfo from './ui/SquadDetailedInfo';
import SquadHeader from './ui/SquadHeader';

type Props = BoxProps & {
  participation: ContestParticipant;
  cards: Card[];
  onClose?: () => void;
  title: string;
  description: string;
  detailed?: boolean;
  contestStatus?: ContestStatus;
  showTutorial?: () => void;
};

const Squad: FC<Props> = ({
  onClose,
  participation,
  cards,
  description,
  title,
  detailed,
  contestStatus,
  showTutorial,
  ...props
}) => {
  const submitted = participation.status === 'completed';

  return (
    <Box {...props}>
      {submitted && participation.booster?.isGlowing && (
        <Spinner
          color={participation.booster?.backgroundColor ?? '#FFD700'}
          p="20px"
          pos="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          w="100%"
          zIndex={0}
        />
      )}
      <SquadHeader
        title={title}
        description={description}
        onClose={onClose}
        showTutorial={showTutorial}
        participation={participation}
      />
      <SquadDeck cards={cards} participation={participation} />
      <SquadCutCards cards={cards} />
      {detailed && <SquadDetailedInfo participation={participation} cards={cards} contestStatus={contestStatus} />}
    </Box>
  );
};

export default Squad;
