import React, { useMemo } from 'react';

import { Box, Img } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { Contest } from 'models';
import { formatDuration } from 'utils/formatDuration';

const NextContestCount: React.FC<{ data: Contest }> = ({ data }) => {
  const now = dayjs().tz('America/New_York');
  const startsAtNewYork = dayjs(data.startsAt).tz('America/New_York');
  const startdDiff = startsAtNewYork.diff(now);
  const durationUntilLocks = dayjs.duration(startdDiff);
  return (
    <Box
      bg="#F83C3C"
      p="8px 0 12px"
      pos="absolute"
      bottom="0"
      left="0"
      right="0"
      overflow="hidden"
      textAlign="center"
      margin="0 -12px"
    >
      <Box mt="4px" fontSize="14px" fontWeight="700" lineHeight="16.8px">
        Next contest in {formatDuration(durationUntilLocks)}
      </Box>
    </Box>
  );
};

export default NextContestCount;
