import { useCallback, useEffect, useState } from 'react';

import { keyframes } from '@chakra-ui/react';
import { Card, CardStatus } from 'models';

import { useChooseCardMutation, useCompleteParticipantMutation } from 'api/contest';

import useContest from './useContest';
import usePlayer from './usePlayer';

// const revealAnimation = keyframes`
//   0% { transform: translateX(-100%) rotate(0deg) scale(0); opacity: 0;  }
//   100% { transform: translateX(0) rotate(360deg) scale(1); opacity: 1; }
// `;

const revealAnimation = keyframes`
 0% {
    transform: translate(-200%, 0) rotate(-30deg) scale(1.2);
    box-shadow: 40px 40px 60px -5px rgba(0,0,0,0.5); 
  } 
  100% { 
    transform: translate(0px, 0px) rotate(0deg) scale(1);
    box-shadow: 0px 10px 10px -5px rgba(0,0,0,1);
  }
`;

const defaultAnimation = keyframes`
  0% {
    transform: translate(0px, 0px) rotate(0deg) scale(1);
  }
  50% {
  }
  100% { 
    transform: translate(20px, 60vh) rotate(10deg) scale(.3);
    opacity: .5;
  } 
`;

const cutAnimation = keyframes`
0% { 
    transform: translate(0px, 0px) rotate(0deg) scale(1);
    box-shadow: 0px 10px 10px -5px rgba(0,0,0,1);   
  } 
  100% {
    transform: translate(200%, 0px) rotate(20deg) scale(.8);
    box-shadow: 40px 40px 60px -5px rgba(0,0,0,0.5);
    opacity: 0.5;
  }
`;

const benchAnimation = keyframes`
 0% {
    transform: translate(0px, 0px)
  }  
  50% {
  }   
  100% {  
    transform: translate(50vw , 60vh) rotate(20deg) scale(0.3);     
    opacity: .5; 
  }
`;

const useBuilder = () => {
  const { contest, isLoading, isFetching, error: contestError, participation, setParticipation } = useContest();

  const card = participation?.cards.lastCardWithoutStatus;
  const cardsWithStatus = participation?.cards.cardsWithStatus ?? [];
  const selectedCards = cardsWithStatus.filter((card) => card.status === 'start');
  const cutCards = cardsWithStatus.filter((card) => card.status === 'cut');
  const benchCard = cardsWithStatus.find((card) => card.status === 'bench');

  const [isAnimating, setIsAnimating] = useState(false);
  const [animation, setAnimation] = useState<string>(revealAnimation);

  const [chooseCardMutation] = useChooseCardMutation();
  const player = usePlayer(card ?? null);

  const [completeParticipant, { error: completeError, isLoading: isCompleteParticipantLoading }] =
    useCompleteParticipantMutation();

  const chooseCard = useCallback(
    async (card: Card, status: CardStatus) => {
      if (!contest || isAnimating) return;
      setIsAnimating(true);
      const animation = (() => {
        if (status === 'cut') return cutAnimation;
        if (status === 'bench') return benchAnimation;
        return defaultAnimation;
      })();
      setAnimation(animation);
      // TODO: refactor
      setTimeout(async () => {
        const { data } = await chooseCardMutation({
          contestId: contest.id,
          cardId: card.id,
          status,
        });
        setParticipation(data ?? null);
      }, 0);
    },
    [contest]
  );

  useEffect(() => {
    setAnimation(revealAnimation);
    setIsAnimating(false);
  }, [player]);

  const finish = useCallback(async () => {
    if (!participation) return;
    const { data, error } = await completeParticipant({
      participantId: participation.id,
    });
    if (error) {
      console.error(error);
    } else {
      setParticipation(data);
    }
  }, [participation]);

  return {
    contest,
    participation,
    isLoading: isLoading || isCompleteParticipantLoading,
    error: contestError ?? completeError ?? null,
    chooseCard,
    finish,
    selectedCards,
    benchCard,
    card,
    cutCards,
    animation,
    player,
    cardsWithStatus: cardsWithStatus ?? [],
    isAnimating,
    isFetching,
  };
};

export default useBuilder;
