import { Booster, CardStatus, ContestParticipant, ContestResponse, DailySpin, DailySpinStatus, Player } from 'models';

import { api } from './api';

const playerApiUrl = process.env.REACT_APP_PLAYER_API_URL || 'https://clutchpoints-middlepoint.4taps.me';

const contestApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getContest: builder.query<ContestResponse, void>({
      query: () => {
        return {
          url: `contest/`,
        };
      },
    }),
    getContestParticipant: builder.query<ContestParticipant, number>({
      query: (id) => {
        return {
          url: `contest/participant/`,
          params: {
            contestId: id,
          },
        };
      },
    }),

    getSpin: builder.query<DailySpin, void>({
      query: () => {
        return {
          url: `contest/spin/`,
        };
      },
    }),

    createContestParticipant: builder.mutation<ContestParticipant, number>({
      query: (contestId) => ({
        url: `contest/participant/`,
        method: 'POST',
        body: {
          contestId,
        },
        headers: {
          accept: 'application/json',
        },
      }),
    }),
    chooseCard: builder.mutation<ContestParticipant, { contestId: number; cardId: number; status: CardStatus }>({
      query: (body) => ({
        url: `contest/participant/card/`,
        method: 'POST',
        body: body,
        headers: {
          accept: 'application/json',
        },
      }),
    }),

    getPlayerByID: builder.query<Player, string>({
      query: (id: string) => {
        return {
          url: `${playerApiUrl}/api/front/players/${id}/`,
        };
      },
    }),

    getBoosters: builder.query<Booster[], void>({
      query: () => {
        return {
          url: `contest/booster/`,
        };
      },
    }),
    getParticipantBoosters: builder.query<Booster[], void>({
      query: () => {
        return {
          url: `contest/participant/booster/`,
        };
      },
    }),

    checkSpin: builder.query<DailySpinStatus, void>({
      query: () => {
        return {
          url: `contest/spin/check/`,
        };
      },
    }),

    setParticipantBooster: builder.mutation<ContestParticipant, { participantId: number; boosterId: number }>({
      query: (body) => ({
        url: `contest/participant/booster/`,
        method: 'POST',
        body: body,
        headers: {
          accept: 'application/json',
        },
      }),
    }),
    completeParticipant: builder.mutation<ContestParticipant, { participantId: number }>({
      query: (body) => ({
        url: `contest/participant/complete/`,
        method: 'POST',
        body: body,
        headers: {
          accept: 'application/json',
        },
      }),
    }),
  }),
});

export const {
  useGetContestQuery,
  useLazyGetContestQuery,
  useGetContestParticipantQuery,
  useLazyGetContestParticipantQuery,
  useCreateContestParticipantMutation,
  useGetSpinQuery,
  useLazyGetSpinQuery,
  useGetPlayerByIDQuery,
  useLazyGetPlayerByIDQuery,
  useChooseCardMutation,
  useGetBoostersQuery,
  useLazyGetBoostersQuery,
  useCheckSpinQuery,
  useLazyCheckSpinQuery,
  useGetParticipantBoostersQuery,
  useLazyGetParticipantBoostersQuery,
  useSetParticipantBoosterMutation,
  useCompleteParticipantMutation,
} = contestApi;
