import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Flex, FormControl, Grid, Input, Link, SlideFade, useToast } from '@chakra-ui/react';
import { RegExpMatcher, englishDataset, englishRecommendedTransformers } from 'obscenity';
import routes from 'routes';

import { useGetUserQuery } from 'api/user';
import { useUpdateUserMutation } from 'api/user';

type FormData = {
  username: string;
};

const EditUserName = () => {
  const {
    handleSubmit,
    getValues,
    register,
    getFieldState,
    watch,
    formState: { errors, isValid, isDirty, touchedFields },
  } = useForm<FormData>({ mode: 'onChange' });
  const [isLoading, setIsLoading] = useState(false);
  const { data: user, isLoading: isUserLoading } = useGetUserQuery();
  const [updateUser, updateUserReq] = useUpdateUserMutation();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();
  const toast = useToast();
  const {
    isError: isUpdateError,
    error: updateError,
    isLoading: isUserUpdating,
    isSuccess,
    originalArgs,
    data,
  } = updateUserReq;

  const filter = new RegExpMatcher({
    ...englishDataset.build(),
    ...englishRecommendedTransformers,
  });

  const onSubmit = (formData: FormData) => {
    updateUser({ ...user, username: formData.username });
  };

  useEffect(() => {
    const username = watch('username');
    const state = getFieldState('username');
    if (!!errors.username?.message) {
      setErrorMessage(errors.username?.message);
    } else if (isUpdateError && originalArgs?.username === username) {
      setErrorMessage('username is taken already');
    } else if (filter.hasMatch(username)) {
      setErrorMessage('ahh we don’t do that here');
    } else {
      setErrorMessage(null);
    }
  }, [errors, watch('username'), isUpdateError]);

  useEffect(() => {
    if (isUserUpdating || isUserLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isUserUpdating, isUserLoading]);

  useEffect(() => {
    if (isSuccess && !isUpdateError) {
      setTimeout(() => {
        navigate(routes.home, {
          state: {
            userNameUpdateStatus: { message: 'Name Changed', isSuccess: !isUpdateError },
          },
        });
      }, 1000);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isSuccess && data) {
      toast({
        duration: 3000,
        isClosable: true,
        position: 'bottom',
        render: ({ onClose }) => {
          return (
            <Flex
              alignItems="center"
              justifyContent="center"
              p="5px 18px !important"
              backgroundColor="#111111"
              borderRadius="10px"
              gap="10px"
              w="min-content"
              whiteSpace="nowrap"
              margin="0 auto 10px"
              onClick={onClose}
            >
              <Box>Name Changed</Box>
            </Flex>
          );
        },
      });
      setTimeout(() => {
        window.history.replaceState({}, document.title);
      }, 4000);
    }
  }, [isSuccess, data]);

  return (
    <Flex
      flexDirection="column"
      pos="absolute"
      w="100%"
      maxW="500px"
      h="100%"
      backgroundColor="#242424"
      zIndex="999"
      top="0px"
      left="50%"
      p="10px 12px"
      transform="translateX(-50%)"
    >
      <Grid gridTemplateColumns="48px 1fr 48px" gap="8px" alignItems="center" justifyContent="center">
        <Box />
        <Box fontSize="16px" lineHeight="1.2" fontWeight="800" textAlign="center">
          edit info
        </Box>
        <Box
          as={Link}
          href={routes.home}
          display="flex"
          alignItems="center"
          aspectRatio="1/1"
          fontSize="14px"
          lineHeight="1.2"
          fontWeight="500"
          textAlign="right"
          _hover={{
            textDecoration: 'none',
            opacity: '0.5',
            transition: 'opacity 0.2s',
          }}
        >
          close
        </Box>
      </Grid>

      <Flex
        as="form"
        flexDirection="column"
        onSubmit={handleSubmit(onSubmit)}
        gap="24px"
        flex="1"
        justifyContent="center"
        p="0 12px"
        pos="relative"
      >
        <Box fontSize="24px" lineHeight="1.2" fontWeight="800" textAlign="center">
          change username
        </Box>
        <Flex flexDirection="column" gap="16px" pos="relative">
          <FormControl isInvalid={!!errors.username}>
            <Input
              id="username"
              placeholder={user?.username}
              {...register('username', {
                required: 'Can’t be empty',
                minLength: { value: 3, message: "it's too short" },
                maxLength: { value: 24, message: 'it’s too long, should be 3-24 characters' },
                // pattern: {
                //   value: /^[a-zA-Z0-9._-]+$/,
                //   message: 'Use letters, numbers, dots, hyphens or underscores only',
                // },
              })}
              opacity={isDirty ? '1' : '0.4'}
              boxShadow="none !important"
              _focus={{ opacity: '1', borderColor: '#fff', outline: 'none' }}
              _focusVisible={{
                opacity: '1',
                borderColor: (!isValid || !!errorMessage) && isDirty ? '#f93c3d' : '#fff',
                outline: 'none',
              }}
            />
          </FormControl>
          <Button
            type="submit"
            isDisabled={!isValid || !!errorMessage || !!data}
            isLoading={isLoading}
            data-cy="submit-button"
          >
            save
          </Button>
          {errorMessage && (
            <SlideFade
              in={true}
              offsetY="-20px"
              style={{ position: 'absolute', top: 'calc(100% + 20px)', textAlign: 'center', width: '100%' }}
            >
              <Box w="100%" color="#f83c3c">
                {errorMessage}
              </Box>
            </SlideFade>
          )}
        </Flex>
      </Flex>
      <Box fontSize="14px" fontWeight="500" lineHeight="1.2" textAlign="center" opacity="0.6" mb="14px">
        it'll be up on the leaderboards
        <br />
        and squad screen
      </Box>
    </Flex>
  );
};

export default EditUserName;
