import { Box, Fade, keyframes } from '@chakra-ui/react';

import { ReactComponent as LogoIcon } from 'icons/logo.svg';

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
    transform="translate(-50%, -50%)"
  }
  50% {
    transform: scale(0.8);
    transform="translate(-50%, -50%)"
  }
  100% {
    transform: scale(1);
    transform="translate(-50%, -50%)"
  }
`;

const logoShineAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
    `;

export const Loader = () => {
  return (
    <Fade
      in={true}
      unmountOnExit
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1,
        background: '#242424',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        position="absolute"
        // top="50%"
        // left="50%"
        filter="blur(30px)"
        zIndex="0"
        opacity={1}
        animation={`${pulseAnimation} 2s ease-in-out infinite`}
      >
        <LogoIcon />
      </Box>
      <Box zIndex={1} animation={`${logoShineAnimation} 2s ease-in-out infinite`}>
        <LogoIcon />
      </Box>
    </Fade>
  );
};
