import { FC, useEffect, useState } from 'react';

import { Box, Image, keyframes } from '@chakra-ui/react';
import { Booster } from 'models';

const CardBlaster: FC<{
  cardCover: string;
  boosters: Booster[] | null;
  position: { x: number; y: number } | null;
  onLoadEnd?: () => void;

  animation?: string;
}> = ({ cardCover, boosters, position, onLoadEnd }) => {
  const [isMounted, setIsMounted] = useState(true);
  const animationDuration = (boosters?.length || 1) * 0.7;
  useEffect(() => {
    if (animationDuration) {
      onLoadEnd && onLoadEnd();
      setTimeout(() => {
        setIsMounted(false);
      }, animationDuration * 1000);
    }
  }, [boosters, position]);

  const blastAnination =
    boosters?.map(
      (_, index) => keyframes`
    0% {
      transform: translate(-50%, 150vh) scale(0.8);
      opacity: 0.7;
    }
    100% {
      transform:translate(-50%, -150vh) scale(1);
      opacity: 1;
    }
  `
    ) || [];

  const getRandomNumber = () => {
    return Math.floor(Math.random() * 20) - 10;
  };

  if (!isMounted) {
    return null;
  }

  return (
    <>
      {blastAnination.map((animation, index) => (
        <Box
          key={`card-blaster-${index}`}
          position={'absolute'}
          animation={``}
          opacity={1}
          transform={`rotate(${getRandomNumber()}deg)`}
          transformOrigin={'center bottom'}
          style={{
            width: '100%',
            height: '100%',
            zIndex: 1,
            top: 0,
            left: 0,
          }}
        >
          <Box width="100%" height="100%" position="relative" display="block" marginBottom="20px">
            <>
              <Image
                src={cardCover}
                transform={`translate(-50%, 100vh)`}
                position="absolute"
                opacity={0.7}
                top="50%"
                left="50%"
                alt="booster pack"
                height="auto"
                width="200px"
                aspectRatio={'1/1.5'}
                zIndex={index + 1}
                borderRadius={'16px'}
                overflow={'hidden'}
                animation={`${animation} 1.5s forwards ease 0.${index}s`}
                loading="eager"
                boxShadow={'0px 6px 50px -10px rgba(0, 0, 0, 0.3)'}
              />
            </>
          </Box>
        </Box>
      ))}
    </>
  );
};

export default CardBlaster;
