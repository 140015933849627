import { Link } from 'react-router-dom';

import { Box, Grid, Text } from '@chakra-ui/react';
import { ContestParticipant } from 'models';
import routes from 'routes';

type SquadHeaderProps = {
  title: string;
  description: string;
  onClose?: () => void;
  participation: ContestParticipant;
  showTutorial?: () => void;
};

const SquadHeader = ({ title, description, onClose, participation, showTutorial }: SquadHeaderProps) => {
  return (
    // <Box pos="relative" textAlign="center" p="14px 0 4px" zIndex="10">
    //   <Box fontSize="18px" fontWeight="800" lineHeight="21.6px">
    //     {title}
    //   </Box>

    //   <Box fontSize="14px" fontWeight="500" lineHeight="15.4px" textAlign="center" mt="4px">
    //     {description}
    //   </Box>

    //   {onClose ? (
    //     <Box
    //       pos="absolute"
    //       right="16px"
    //       top="50%"
    //       transform="translateY(-50%)"
    //       fontSize="14px"
    //       fontWeight="500"
    //       onClick={onClose}
    //       cursor="pointer"
    //     >
    //       close
    //     </Box>
    //   ) : (
    //     participation.status === 'completed' && (
    //       <Box
    //         pos="absolute"
    //         right="16px"
    //         top="50%"
    //         transform="translateY(-50%)"
    //         fontSize="14px"
    //         fontWeight="500"
    //         as={Link}
    //         to={routes.home}
    //       >
    //         close
    //       </Box>
    //     )
    //   )}
    // </Box>

    <Grid gridTemplateColumns="48px 1fr 48px" gap="8px" py="8px" px="12px">
      <Box display="flex" alignItems="center" justifyContent="center">
        {participation.status !== 'completed' && (
          <Box
            onClick={showTutorial}
            height="36px"
            width="36px"
            borderRadius="50%"
            backgroundColor="#111111"
            display="flex"
            alignItems="center"
            justifyContent="center"
            fontSize="20px"
          >
            ?
          </Box>
        )}
      </Box>
      <Box textAlign="center">
        <Text fontSize="18px" fontWeight="800" lineHeight="21.6px">
          {title}
        </Text>
        <Text variant="text1" mt="4px">
          {description}
        </Text>
      </Box>
      <Box>
        {' '}
        {onClose ? (
          <Box fontSize="14px" fontWeight="500" onClick={onClose} cursor="pointer">
            close
          </Box>
        ) : (
          participation.status === 'completed' && (
            <Box fontSize="14px" fontWeight="500" as={Link} to={routes.home}>
              close
            </Box>
          )
        )}
      </Box>
    </Grid>
  );
};

export default SquadHeader;
