import { border, defineStyleConfig } from '@chakra-ui/react';

const ButtonConfig = defineStyleConfig({
  baseStyle: {
    color: 'white',
  },
  sizes: {
    md: {
      fontSize: '20px',
      fontWeight: '800',
      borderRadius: '16px',
      h: '60px',
      px: '26px',
      pb: '6px',
      boxShadow: '0px -4px 0px 0px #00000080 inset, 0px 0px 50px 0px #F83C3C40',
      textShadow: '0px -1px 0px #00000080',
      transition: 'all 0.2s ease-in-out',
    },
  },
  variants: {
    primary: {
      bg: 'brand.500',
      _hover: {
        bg: 'brand.500',
      },
      _focus: {
        bg: 'brand.500',
      },
      _active: {
        bg: 'brand.600',
        boxShadow: '0px -2px 0px 0px #00000080 inset, 0px 0px 20px 0px #F83C3C40',
        pt: '2px',
        mt: '2px',
        h: '58px',
      },
      _disabled: {
        opacity: '0.4',
        boxShadow: '0px -4px 0px 0px #00000080 inset, 0px 0px 50px 0px #F83C3C40',
        _hover: {
          bg: 'brand.500 !important',
        },
        _focus: {
          bg: 'brand.500 !important',
        },
        _active: {
          bg: 'brand.500  !important',
        },
      },
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'primary',
  },
});

export default ButtonConfig;
