import { Navigate } from 'react-router-dom';

import { Box, Grid } from '@chakra-ui/react';
import routes from 'routes';

import SignIn from 'components/auth/SignIn';
import SocialSignIn from 'components/auth/SocialSignIn';

import { useAppSelector } from 'hooks/app';

const SignInPage = () => {
  const isAuthicated = useAppSelector((store) => store.auth.isAuthicated);

  if (isAuthicated) {
    return <Navigate to={routes.home} replace />;
  }
  return (
    <Box px="24px">
      <SignIn pos="absolute" top="50%" width="100%" transform="translateY(-50%)" />
    </Box>
  );
};

export default SignInPage;
