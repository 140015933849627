import { type User } from 'models';

import { api } from './api';

const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<User, void>({
      query: () => ({
        url: '/user/',
      }),
    }),
    updateUser: builder.mutation<User, { username: string }>({
      query: ({ username }) => ({
        url: `/user/`,
        method: 'POST',
        body: { username },
      }),
    }),
  }),
});

export const { useGetUserQuery, useLazyGetUserQuery, useUpdateUserMutation } = authApi;
