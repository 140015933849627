import { FC, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Link } from 'react-router-dom';

import { Box, Flex, Img, Portal } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { ContestParticipant, ContestStatus } from 'models';

import CardPlaceholder from 'components/builder/CardPlaceholder';
import Squad from 'components/builder/Squad/Squad';

import { ReactComponent as NoneIcon } from 'icons/none.svg';
import { ReactComponent as RightArrowIcon } from 'icons/right-arr.svg';

type Props = {
  participant: ContestParticipant;
  contestStatus?: ContestStatus;
};

const MySquad: FC<Props> = ({ participant, contestStatus }) => {
  if (participant.status !== 'completed') {
    return null;
  }
  const booster = participant.booster;

  const startCards = participant.cards.cardsWithStatus.filter((card) => card.status === 'start');
  const benchCard = participant.cards.cardsWithStatus.find((card) => card.status === 'bench');
  const cutCards = participant.cards.cardsWithStatus.filter((card) => card.status === 'cut');

  const totalPoints = participant.totalPoints === 0 || !participant.totalPoints ? 'TBA' : participant.totalPoints;

  const [detailShowed, setDetailShowed] = useState(false);

  useEffect(() => {
    if (detailShowed) {
      document.body.classList.add('locked');
    } else {
      document.body.classList.remove('locked');
    }
    return () => {
      document.body.classList.remove('locked');
    };
  }, [detailShowed]);

  if (detailShowed) {
    const date = dayjs(participant.updated).tz('America/New_York');
    const dateStr = date.format('MMM D');

    return (
      <Portal>
        <Squad
          participation={participant}
          cards={participant.cards.cardsWithStatus}
          pos="fixed"
          top="0"
          bottom="0"
          left="0"
          right="0"
          bg="var(--chakra-colors-bg)"
          onClose={() => setDetailShowed(false)}
          title="Start Bench Cut"
          description={`${dateStr} · ${participant.user.username}`}
          overflow="auto"
          detailed
          zIndex="100"
          contestStatus={contestStatus}
          className="portal"
        />
      </Portal>
    );
  }

  return (
    <Box bg="#CE5252" p="12px 16px 20px" borderRadius="16px" mt="6px">
      <Flex align="center" justify="space-between">
        <Box fontSize="16px" fontWeight="800" lineHeight="19px">
          my squad
        </Box>
        <Flex
          fontSize="14px"
          fontWeight="700"
          lineHeight="16.8px"
          opacity="0.8"
          align="center"
          gap="7px"
          onClick={() => setDetailShowed(true)}
        >
          details
          <Box as={RightArrowIcon} w="5px" h="8px" />
        </Flex>
      </Flex>
      <Flex justify="center" align="center" mt="16px">
        {startCards.map((card) => (
          <CardPlaceholder key={card.id} ml="-2px" mr="-2px" boxShadow="4px 0px 20px 0px #00000080" card={card} />
        ))}
        {benchCard && (
          <CardPlaceholder
            ml="-2px"
            mr="-2px"
            boxShadow="4px 0px 20px 0px #00000080"
            bgColor="#4F4F4F"
            color="#D2C9C9"
            card={benchCard}
          />
        )}
        {booster ? (
          <Box
            pos="relative"
            ml="-2px"
            mr="-2px"
            w="60px"
            h="60px"
            backgroundColor={booster.backgroundColor ?? 'transparent'}
            borderRadius="14px"
            backgroundImage={`url(${booster.image})`}
            backgroundSize="cover"
            backgroundPosition="center center"
            boxShadow="4px 0px 20px 0px #00000080"
          >
            {booster.icon && (
              <Img src={booster.icon} pos="absolute" top="50%" left="50%" w="60px" transform="translate(-50%, -50%)" />
            )}
          </Box>
        ) : (
          <Box
            pos="relative"
            ml="-2px"
            mr="-2px"
            w="60px"
            h="60px"
            backgroundColor="#111111"
            borderRadius="14px"
            boxShadow="4px 0px 20px 0px #00000080"
          >
            <Box as={NoneIcon} pos="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" />
          </Box>
        )}
      </Flex>
      <Flex mt="16px" justify="center" gap="24px">
        <Box textAlign="center" w="80px">
          <Box fontSize="20px" fontWeight="800" lineHeight="24px">
            {totalPoints}
          </Box>
          <Box fontSize="12px" fontWeight="600" lineHeight="14.4px" opacity="0.8">
            earnings
          </Box>
        </Box>
        <Box textAlign="center" w="80px">
          <Box fontSize="20px" fontWeight="800" lineHeight="24px">
            {participant.place ?? 'TBA'}
          </Box>
          <Box fontSize="12px" fontWeight="600" lineHeight="14.4px" opacity="0.8">
            place
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default MySquad;
