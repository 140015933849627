import { FC } from 'react';

import { Box, Fade, Flex, Grid } from '@chakra-ui/react';
import { Contest } from 'models';

import NextGame from 'components/layout/NextGame';

const NoParticipants: FC<{ nextContest: Contest | null }> = ({ nextContest }) => {
  return (
    <Grid pos="relative" pt="8px" pb="20px" gridTemplateRows="1fr auto" flex="1" height="100%">
      <Flex flexDir="column" align="center" justify="center" gap="16px" px="12px" flex="1">
        <Fade in={true}>
          <Box fontSize="14px" fontWeight="600" lineHeight="16.8px" opacity="0.8" textAlign="center">
            No participants in this contest.
          </Box>
        </Fade>
      </Flex>

      {/* {nextContest && <NextGame data={nextContest} />} */}
    </Grid>
  );
};

export default NoParticipants;
