import { Navigate } from 'react-router-dom';

import routes from 'routes';

import Boosters from 'components/booster/Boosters';

import useBoosters from 'hooks/useBoosters';

const BoostersPage = () => {
  const { boosters, isLoading, contest, participation, setBooster, selectedBooster } = useBoosters();

  if (isLoading || !participation) return null;

  if (contest?.status !== 'active') {
    return <Navigate to={routes.home} replace />;
  }

  if (participation?.status === 'cards_pending') {
    return <Navigate to={routes.builder} replace />;
  }

  if (participation.booster || participation.status === 'completed') {
    return <Navigate to={routes.squad} replace />;
  }

  return <Boosters boosters={boosters} onSelect={setBooster} title="Use a boost" subTitle="Earn coins on top of PTS" />;
};

export default BoostersPage;
