import { useGetSpinQuery } from 'api/contest';

const useSpinner = (isAvailableToGetBooster: boolean) => {
  const { isLoading, isFetching, data, error } = useGetSpinQuery(undefined, { skip: !isAvailableToGetBooster });

  return {
    isLoading,
    isFetching,
    data,
    error,
  };
};

export default useSpinner;
