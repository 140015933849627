import { FC } from 'react';
import { Link } from 'react-router-dom';

import { Box, BoxProps, Img, Text } from '@chakra-ui/react';
import { Booster } from 'models';
import routes from 'routes';

import BoosterPlaceholder from './BoosterPlaceholder';

type Props = { booster?: Booster; isDisabled?: boolean } & BoxProps;

const BoosterCard: FC<Props> = ({ booster, isDisabled, ...props }) => {
  return (
    <>
      <Box
        pos="relative"
        w="108px"
        borderRadius="8px"
        overflow="hidden"
        boxShadow={!booster ? undefined : '0px 6px 50px 0px #000000CC'}
        border={booster ? undefined : '1px dashed #666666'}
        bgColor="#242424"
        as={isDisabled ? undefined : Link}
        to={routes.boosters}
        {...props}
      >
        {booster ? (
          <Box
            pos="relative"
            backgroundPosition="center center"
            backgroundSize="cover"
            bgImage={`url(${booster.image})`}
            bgSize="cover"
            bgPos="center center"
            width="100%"
            height="100%"
            borderRadius="4px"
          >
            <Text
              color={booster.textColor ?? undefined}
              variant="boosterName"
              pos="absolute"
              top="7px"
              left="7px"
              right="7px"
              fontSize="10px"
              fontWeight="500"
              lineHeight="12px"
            >
              {booster.name}
            </Text>
            <Text
              variant="boosterMultiplier"
              pos="absolute"
              bottom="9px"
              left="7px"
              right="7px"
              fontSize="10px"
              fontWeight="500"
              lineHeight="12px"
              color={booster.textColor ?? undefined}
            >
              {booster.pointsMultiplier} x PTS
              <br />
              {booster.attributeMultiplier} x {booster.attributeDisplay?.toUpperCase()}
              <br />
            </Text>
            {booster.icon && (
              <Img src={booster.icon} pos="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" />
            )}
          </Box>
        ) : (
          <BoosterPlaceholder isDisabled={isDisabled} />
        )}
      </Box>
    </>
  );
};

export default BoosterCard;
