import { Booster } from 'models';

import { useGetParticipantBoostersQuery, useSetParticipantBoosterMutation } from 'api/contest';

import { selectSelectedBooster, setParticipation, setSelectedBooster } from 'store/slices/contest';

import { useAppDispatch, useAppSelector } from './app';
import useBuilder from './useBuilder';

const useBoosters = () => {
  const { contest, participation } = useBuilder();

  const {
    data: boosters,
    isLoading: isBoostersLoading,
    refetch: refetchBoosters,
  } = useGetParticipantBoostersQuery(undefined, {
    skip: !contest,
  });

  const isLoading = isBoostersLoading;

  const filteredBoosters = boosters?.filter((booster) => booster.league === contest?.league && !booster.usedAt) ?? [];

  const [setBoosterMutation, { isLoading: isSetBoosterLoading }] = useSetParticipantBoosterMutation();

  const dispatch = useAppDispatch();

  const setBooster = async (booster: Booster) => {
    if (isSetBoosterLoading || !participation) {
      return;
    }
    dispatch(setSelectedBooster(booster));
  };

  const selectedBooster = useAppSelector(selectSelectedBooster);

  const submitBooster = async () => {
    if (isSetBoosterLoading || !participation || !selectedBooster) {
      return;
    }
    const { data, error } = await setBoosterMutation({
      participantId: participation.id,
      boosterId: selectedBooster.id,
    });
    if (data) {
      dispatch(setParticipation(data));
    }
  };

  return {
    boosters: filteredBoosters,
    isLoading,
    contest,
    participation,
    setBooster,
    selectedBooster,
    submitBooster,
    refetchBoosters,
  };
};

export default useBoosters;
