import './instrument';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { ChakraProvider } from '@chakra-ui/react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isToday from 'dayjs/plugin/isToday';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import { store } from 'store';

import App from './app/App';
import './index.css';
import theme from './theme';

async function enableMocking() {
  if (process.env.REACT_APP_PUBLIC_MOCK !== 'enabled') {
    return;
  }

  const { worker } = await import('./mocks/browser');
  return worker.start();
}

// import reportWebVitals from './reportWebVitals';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isToday);
dayjs.extend(isBetween);
dayjs.extend(updateLocale);
dayjs.extend(isSameOrBefore);
dayjs.extend(duration);
dayjs.tz.setDefault('America/New_York');
// dayjs.updateLocale("America/New_York", { weekStart: 1 })
// import { GoogleOAuthProvider } from '@react-oauth/google;

// const googleClientId = '609550829109-m5gdf8j2f264bk47dm4kqplhjnucichj.apps.googleusercontent.com'
enableMocking().then(() => {
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
  root.render(
    // <React.StrictMode>
    <ChakraProvider theme={theme}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </ChakraProvider>

    // </React.StrictMode>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
