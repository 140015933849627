import { useCallback, useEffect } from 'react';

import { Contest, ContestParticipant } from 'models';

import {
  useCreateContestParticipantMutation,
  useGetContestQuery,
  useLazyGetContestParticipantQuery,
} from 'api/contest';

import { setContestResponse, setParticipation } from 'store/slices/contest';

import { useAppDispatch, useAppSelector } from './app';

const useContest = () => {
  const dispatch = useAppDispatch();
  const { previousContest, contest, nextContest, participation } = useAppSelector((store) => store.contest);

  const {
    isLoading: isLoadingContest,
    isFetching: isFetchingContest,
    data,
    error,
  } = useGetContestQuery(undefined, { skip: !!contest });

  const [getParticipation, { isLoading: isLoadingParticipant, isFetching: isFetchingisParticipant }] =
    useLazyGetContestParticipantQuery();

  const [createParticipationMutation, { isLoading: isCreateParticipation }] = useCreateContestParticipantMutation();

  const createParticipation = useCallback(async (contest: Contest) => {
    await createParticipationMutation(contest.id);
  }, []);

  const fetchParticipation = useCallback(async (contest: Contest) => {
    if (contest?.status !== 'active') {
      return;
    }

    const { data, error } = await getParticipation(contest.id);
    if (error && 'status' in error && error.status === 404) {
      await createParticipation(contest);
      await fetchParticipation(contest);
    } else {
      dispatch(setParticipation(data ?? null));
    }
  }, []);

  useEffect(() => {
    if (isLoadingParticipant || !data) return;
    if (error) {
      console.error(error);
    }
    dispatch(setContestResponse(data));
  }, [isLoadingParticipant, data, error]);

  useEffect(() => {
    // TODO: fix dublicate request
    if (isLoadingParticipant || !contest) {
      return;
    }
    fetchParticipation(contest);
  }, [isLoadingParticipant, isCreateParticipation]);

  const isLoading = isLoadingContest || isLoadingParticipant;
  const isFetching = isFetchingContest || isFetchingisParticipant;

  return {
    participation: !isLoading && contest ? participation : null,
    previousContest: !isLoading ? previousContest : null,
    contest: !isLoading ? contest : null,
    nextContest,
    isLoading,
    isFetching,
    error,
    fetchParticipation,
    setParticipation: (participation: ContestParticipant | null) => {
      dispatch(setParticipation(participation));
    },
  };
};
export default useContest;
