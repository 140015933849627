import { FC } from 'react';

import { Box, BoxProps, keyframes } from '@chakra-ui/react';

import { ReactComponent as Star } from 'icons/star.svg';

const rotateAnimation = keyframes`
  from { 
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const fadeInAnimation = keyframes`
  from { 
    opacity: 0; 
  }
  to { 
    opacity: 1; 
  }
`;

const Spinner: FC<BoxProps> = (props) => {
  return (
    <Box
      pos="relative"
      pointerEvents="none"
      overflow="hidden"
      display="flex"
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      <Box
        as={Star}
        transformOrigin="center"
        animation={`${rotateAnimation} 5s linear infinite, ${fadeInAnimation} 1s forwards`}
      />
    </Box>
  );
};

export default Spinner;
