import { FC, useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import { Booster } from 'models';

import BoosterCard from 'components/booster/BoosterCard';

const BoostersFall: FC<{
  cardCover: string;
  start: boolean;
  position: { x: number; y: number } | null;
  boosters?: Booster[] | null;
  activeBooster?: number | null;
  onLoadEnd?: () => void;
}> = ({ cardCover, start, boosters, position, activeBooster, onLoadEnd }) => {
  const animationDuration = boosters && boosters?.length < 2 ? 2 : boosters && boosters.length * 0.7;
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    if (animationDuration) {
      const timer = setTimeout(() => {
        onLoadEnd && onLoadEnd();
        setIsMounted(false);
      }, animationDuration * 1000);

      return () => clearTimeout(timer);
    }
  }, [animationDuration, onLoadEnd]);

  const fallAnination =
    boosters?.map((_, index) => {
      return keyframes`
    0% {
     top: -150vh;
     left: 50%;
     transform: translate(-50%, 0);
    }
    100% {
      top: ${position?.y}px;
      left: ${position?.x}px;
      rotateX(20deg);
    }
  `;
    }) || [];

  if (!isMounted) {
    return null;
  }

  return (
    <>
      {start &&
        boosters?.map((booster, index) => {
          return (
            <Box
              key={`card-fall-${index}`}
              position={'absolute'}
              opacity={1}
              transform={`rotate(0deg)`}
              transformOrigin={'center bottom'}
              style={{
                width: '100%',
                height: '100%',
                zIndex: activeBooster === booster.id ? 2 : 1,
                top: 0,
                left: 0,
                perspective: '1000px',
              }}
            >
              <Box width="100%" height="100%" position="relative" display="block" marginBottom="20px">
                <>
                  <BoosterCard
                    booster={booster}
                    type="spin"
                    transform={`translate(0%, 0%)`}
                    position="absolute"
                    top="-150vh"
                    left="50%"
                    height="auto"
                    width="200px"
                    aspectRatio={'1/1.5'}
                    zIndex={1}
                    borderRadius={'16px'}
                    overflow={'hidden'}
                    animation={start ? `${fallAnination[index]} 1s forwards ease ${(index + index) / 7}s` : ''}
                    boxShadow={'0px 6px 50px -10px rgba(0, 0, 0, 0.01)'}
                  />
                </>
              </Box>
            </Box>
          );
        })}
    </>
  );
};

export default BoostersFall;
