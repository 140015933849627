import { FC } from 'react';

import { Box, BoxProps, Flex, Img } from '@chakra-ui/react';
import { Booster, Card } from 'models';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';

import usePlayer from 'hooks/usePlayer';

const DetailedCard: FC<{ card: Card; booster?: Booster | null }> = ({ card, booster }) => {
  const player = usePlayer(card);
  if (!player) {
    return null;
  }
  const headshot = player?.headshotThumbnails?.small ?? player?.headshotThumbnails?.medium ?? player?.headshot;
  return (
    <Box px="16px" pt="16px" pb="24px">
      <Flex gap="8px" alignItems="flex-start">
        <Box
          w="44px"
          h="44px"
          mx="auto"
          backgroundSize="cover"
          backgroundPosition="center center"
          backgroundImage={`url(${headshot})`}
          borderRadius="16px"
          flexShrink="0"
          flexGrow="0"
          bgColor={card.status === 'bench' ? '#343434' : 'white'}
        />
        <Box color="white" flexBasis="100%">
          <Box fontFamily="JetBrains Mono" fontSize="12px" fontWeight="500" lineHeight="14.4px">
            {player.firstName}
          </Box>
          <Box fontFamily="JetBrains Mono" fontSize="18px" fontWeight="500" lineHeight="18px" mt="2px">
            {player.lastName}
          </Box>
        </Box>
        <Flex flexShrink="0" alignItems="center" justifyContent="center" paddingRight="3px" height="100%" gap="2px">
          <Img w="16px" src="/coin.png" />
          <Box fontSize="18px" fontWeight="700">
            {card.totalPoints}
          </Box>
        </Flex>
      </Flex>
      <Box pl="52px" fontSize="13px" fontWeight="500" color="#FFFFFFCC" lineHeight="15.6px">
        <Flex align="center" justify="space-between">
          <Box>Points in the game</Box>
          <Box
            fontSize="13px"
            fontWeight="600"
            color="white"
            flexBasis="100px"
            flexShrink="0"
            flexGrow="0"
            textAlign="right"
            lineHeight="15.6px"
          >
            {card.nakedScorePoints}
          </Box>
        </Flex>
        {booster && (
          <>
            <Flex align="center" justify="space-between" mt="8px">
              <Box>{capitalizeFirstLetter(booster.rarity)} PTS Boost</Box>
              <Box
                fontSize="13px"
                fontWeight="600"
                color="white"
                flexBasis="100px"
                flexShrink="0"
                flexGrow="0"
                lineHeight="15.6px"
                textAlign="right"
              >
                {booster.pointsMultiplier}x
              </Box>
            </Flex>
            <Flex align="center" justify="space-between" mt="8px">
              <Box>
                {capitalizeFirstLetter(booster.rarity)} {booster.attributeDisplay?.toUpperCase()} Boost
              </Box>
              <Box
                fontSize="13px"
                fontWeight="600"
                color="white"
                flexBasis="100px"
                lineHeight="15.6px"
                flexShrink="0"
                flexGrow="0"
                textAlign="right"
              >
                {booster.attributeMultiplier} x {card.nakedAttributePoints} {booster.attributeDisplay?.toUpperCase()}
              </Box>
            </Flex>
          </>
        )}
      </Box>
    </Box>
  );
};

export default DetailedCard;
