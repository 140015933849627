import Button from './Button';
import Input from './Input';
import Text from './Text';

import { extendTheme } from '@chakra-ui/react';

const colors = {
  bg: '#242424',
  brand: {
    500: '#F83C3C',
    600: '#C12626',
    400: 'rgba(248, 60, 60, 0.4)',
  },
};

const theme = extendTheme({
  colors,

  styles: {
    global: {
      'html, body': {
        backgroundColor: 'bg',
        color: 'white',
      },
      '#root': {
        maxW: '500px',
        mx: 'auto',
        w: '100%',
      },
      '.portal': {
        maxW: '500px',
        mx: 'auto',
        w: '100%',
      },
    },
  },
  components: {
    Text,
    Button,
    Input,
  },
});

export default theme;
