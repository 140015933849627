import { LeaderboardStat } from 'models';

import { api } from './api';

const contestApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getLeaderboard: builder.query<LeaderboardStat, number>({
      query: (contestId: number) => {
        return {
          url: `contest/leaderboard/`,
          params: { contestId },
        };
      },
    }),
  }),
});

export const { useGetLeaderboardQuery } = contestApi;
