import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import routes from 'routes';

import { useAppSelector } from 'hooks/app';

import { selectUser } from 'store/slices/auth';

const Protected: React.FC = () => {
  const isAuthenticated = useAppSelector(selectUser);
  return isAuthenticated ? <Outlet /> : <Navigate to={routes.signIn} replace />;
};

export default Protected;
