import { FC, useState } from 'react';

import { Box, Flex, Img, Portal } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { ContestParticipant } from 'models';

import CardPlaceholder from 'components/builder/CardPlaceholder';
import Squad from 'components/builder/Squad/Squad';

import { ReactComponent as NoneIcon } from 'icons/none.svg';
import { ReactComponent as RightArrowIcon } from 'icons/right-arr.svg';

import ParticipantPosition from './ParticipantPosition';

type Props = {
  participant: ContestParticipant;
  position: number;
};

const LeaderboardRow: FC<Props> = ({ participant, position }) => {
  const [showParticipantDetail, setShowParticipantDetail] = useState(false);
  if (participant.status !== 'completed') {
    return null;
  }

  const booster = participant.booster;
  const user = participant.user;

  const startCards = participant.cards.cardsWithStatus.filter((card) => card.status === 'start');
  const benchCard = participant.cards.cardsWithStatus.find((card) => card.status === 'bench');

  if (showParticipantDetail) {
    const date = dayjs(participant.updated).tz('America/New_York');
    const dateStr = date.format('MMM D');

    return (
      <Portal>
        <Squad
          participation={participant}
          cards={participant.cards.cardsWithStatus}
          pos="fixed"
          top="0"
          bottom="0"
          left="0"
          right="0"
          bg="var(--chakra-colors-bg)"
          onClose={() => setShowParticipantDetail(false)}
          title="Start Bench Cut"
          description={`${dateStr} · ${participant.user.username}`}
          overflow="auto"
          detailed
          zIndex="100"
          contestStatus={'completed'}
          className="portal"
        />
      </Portal>
    );
  }

  return (
    <>
      <Box p="12px 0 16px" mt="4px" borderBottom="0.5px solid #FFFFFF4D" onClick={() => setShowParticipantDetail(true)}>
        <Flex alignItems="center" justify="space-between" color={position === 0 ? '#FFD700' : 'white'}>
          <Flex align="end" flexBasis="100%">
            <ParticipantPosition position={position + 1} />
          </Flex>
          <Flex
            gap="12px"
            align="center"
            fontSize="14px"
            fontWeight="800"
            lineHeight="16.94px"
            flexShrink="0"
            flexGrow="0"
            flexBasis="286px"
          >
            <Flex align="center" gap="8px" maxW="218px" marginRight="auto">
              <Flex
                w="36px"
                h="36px"
                borderRadius="12px"
                background="#FFFFFF1A"
                fontSize="16px"
                fontWeight="800"
                justify="center"
                align="center"
                color="#FFFFFFCC"
                flexShrink="0"
                textTransform="uppercase"
                backgroundPosition="center center"
                backgroundSize="cover"
                backgroundImage={user.avatarUrl ? `url(${user.avatarUrl})` : undefined}
              >
                {user.avatarUrl ? null : user.username[0]}
              </Flex>
              <Box textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
                {user.username}
              </Box>
            </Flex>
            <Flex align="center" gap="2px">
              {participant.totalPoints}
              <Img w="20px" h="20px" src="/coin.png" />
            </Flex>
          </Flex>
          <Box></Box>
        </Flex>
        <Flex justify="flex-end" mt="8px">
          {startCards.map((card) => (
            <CardPlaceholder key={card.id} ml="-4px" boxShadow="4px 0px 20px 0px #00000080" card={card} />
          ))}
          {benchCard && (
            <CardPlaceholder
              ml="-4px"
              boxShadow="4px 0px 20px 0px #00000080"
              bgColor="#4F4F4F"
              color="#D2C9C9"
              card={benchCard}
            />
          )}
          {booster ? (
            <Box
              pos="relative"
              ml="-4px"
              w="60px"
              h="60px"
              backgroundColor={booster.backgroundColor ?? 'transparent'}
              borderRadius="14px"
              backgroundImage={`url(${booster.image})`}
              backgroundSize="cover"
              backgroundPosition="center center"
              boxShadow="4px 0px 20px 0px #00000080"
            >
              {booster.icon && (
                <Img
                  src={booster.icon}
                  pos="absolute"
                  top="50%"
                  left="50%"
                  w="60px"
                  transform="translate(-50%, -50%)"
                />
              )}
            </Box>
          ) : (
            <Box
              pos="relative"
              ml="-4px"
              w="60px"
              h="60px"
              backgroundColor="#111111"
              borderRadius="14px"
              boxShadow="4px 0px 20px 0px #00000080"
            >
              <Box as={NoneIcon} pos="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" />
            </Box>
          )}
        </Flex>
      </Box>
    </>
  );
};

export default LeaderboardRow;
