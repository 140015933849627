import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { User } from 'models';

type AuthState = {
  isAuthicated: boolean;
  isLoading: boolean;
  user?: User;
};

const initialState: AuthState = {
  isAuthicated: false,
  isLoading: true,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsAuthicated(state, action: PayloadAction<boolean>) {
      state.isAuthicated = action.payload;
      state.isLoading = false;
    },
    setUser(state, action: PayloadAction<User | undefined>) {
      state.user = action.payload;
      state.isLoading = false;
    },
  },
  selectors: {
    selectUser: (state) => state.user,
    selectIsAuthicated: (state) => state.isAuthicated,
  },
});

export default authSlice.reducer;

export const { setUser, setIsAuthicated } = authSlice.actions;
export const { selectUser, selectIsAuthicated } = authSlice.selectors;
