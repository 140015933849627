import { FC, useCallback } from 'react';
import ReactGA from 'react-ga4';
import { useForm } from 'react-hook-form';

import { Box, BoxProps, Button, Flex, FormControl, Grid, Input, Text } from '@chakra-ui/react';

import { useSignInMutation } from 'api/auth';

import SocialSignIn from './SocialSignIn';

type FormData = {
  email: string;
};

const SignIn: FC<BoxProps> = (props) => {
  const [signIn, signInReq] = useSignInMutation();

  const {
    handleSubmit,
    register,
    setError,
    getValues,
    formState: { errors, isValid },
  } = useForm<FormData>();
  const email = getValues().email;

  const onSubmit = useCallback(
    async ({ email }: FormData) => {
      ReactGA.event({ category: 'User', action: 'Sign In' });
      const { error } = await signIn({
        email,
        redirectUri: window.location.origin,
      });
      if (error) {
        console.log(error);
        if ('originalStatus' in error && error.originalStatus === 500) {
          setError('email', { message: 'something’s wrong' });
        } else {
          setError('email', { message: 'something’s wrong with an email' });
        }
      } else {
        // Cookies.set('sbc_access_token', data.accessToken)
        // dispatch(setUser(data))
        // dispatch(setIsAuthicated(true))
      }
    },
    [signIn]
  );

  if (signInReq.isSuccess) {
    return (
      <Box
        w="100%"
        h="100%"
        minH="100%"
        display="flex"
        flexDir="column"
        justifyContent="center"
        textAlign="center"
        gap="12px"
        data-cy="signin-success"
      >
        <Box fontSize="24px" fontWeight="800" lineHeight="29px">
          we sent a magic
          <br />
          link to your email
        </Box>
        <Box fontSize="14px" fontWeight="500">
          {email}
        </Box>
      </Box>
    );
  }

  return (
    <Grid w="100%" h="100%" minH="100%" gridTemplateRows="1fr auto" pb="24px">
      <Box pos="relative">
        <Box as="form" {...props} onSubmit={handleSubmit(onSubmit)} data-cy="signin-form">
          <Box textAlign="center">
            <Text variant="title">start bench cut</Text>
            <Text variant="text1" mt="8px">
              join the game
            </Text>
          </Box>
          <Flex flexDir="column" gap="16px" mt="24px" alignItems="stretch">
            <FormControl isInvalid={!!errors.email}>
              <Input
                placeholder="email address"
                type="email"
                autoComplete="email"
                data-cy="email-input"
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: 'Invalid Email',
                  },
                })}
              />
            </FormControl>
            <Button type="submit" isDisabled={!isValid} isLoading={signInReq.isLoading} data-cy="submit-button">
              next
            </Button>
            <Box color="brand.500" textAlign="center" h="28px">
              {errors.email?.message}
            </Box>
          </Flex>
        </Box>
      </Box>
      <SocialSignIn />
    </Grid>
  );
};

export default SignIn;
