import { Box, keyframes } from '@chakra-ui/react';

const shineAnimation = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
`;

const BoosterCardSkeleton = () => {
  return (
    <Box
      pos="relative"
      bgColor={'rgba(0,0,0,0.1)'}
      width={'100%'}
      minHeight={'255px'}
      aspectRatio={'1/1.5'}
      boxShadow="0px 6px 50px 0px #000000CC"
      borderRadius="16px"
      transition="opacity 0.2s linear"
      backgroundImage="linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.1) 50%, rgba(255,255,255,0) 100%)"
      backgroundSize="400px 100%"
      animation={`${shineAnimation} 1.5s infinite linear`}
    ></Box>
  );
};

export default BoosterCardSkeleton;
