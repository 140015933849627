import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { setUser } from '@sentry/react';
import Cookies from 'js-cookie';
import routes from 'routes';

import { useAppDispatch } from 'hooks/app';

import { setIsAuthicated } from 'store/slices/auth';

const LogoutPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setIsAuthicated(false));
    // dispatch(setUser(null));
    Cookies.remove('sbc_access_token');
    navigate(routes.home);
  }, []);

  return null;
};

export default LogoutPage;
