import { Box, keyframes } from '@chakra-ui/react';

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const sparkGrowAnimation = keyframes`
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(3);
    opacity: 0;
  }
`;

const SpinnerLoader = () => {
  return (
    <Box
      position="fixed"
      top="0"
      left="50%"
      transform="translateX(-50%)"
      w="100%"
      h="100%"
      maxW="500px"
      margin="0 auto"
    >
      <Box
        pos="absolute"
        width="100%"
        h="100%"
        top="0"
        left="0"
        opacity="0"
        bgImage="radial-gradient(circle, rgba(36,36,36,0) 10%, rgba(191,30,0,0.1) 140%)"
        animation={`${fadeInAnimation} 1s ease-in-out forwards`}
      />
      <Box
        pos="absolute"
        width="100%"
        h="100vh"
        top="0"
        left="0"
        opacity="0"
        bgImage="radial-gradient(circle, rgba(36,36,36,0) 40%, rgba(255,40,0,0.2) 140%)"
        animation={`${fadeInAnimation} 1s ease-in-out forwards 0.2s`}
      />
      <Box
        pos="absolute"
        width="100%"
        h="100vh"
        top="0"
        left="0"
        opacity="0"
        bgImage="radial-gradient(circle, rgba(36,36,36,0) 80%, rgba(255,53,15,0.5) 140%)"
        animation={`${fadeInAnimation} 1s ease-in-out forwards 0.4s`}
      />
      <Box
        pos="absolute"
        width="100%"
        h="100vh"
        top="0"
        left="0"
        opacity="0"
        bgImage="radial-gradient(circle, rgba(36,36,36,0) 90%, rgba(255,53,15,1) 140%)"
        animation={`${fadeInAnimation} 1s ease-in-out forwards 0.6s`}
      />

      <Box
        pos="absolute"
        width="100%"
        h="100vh"
        top="0"
        left="0"
        scale={0}
        opacity={0}
        bgImage="radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(36,36,36,0) 20%)"
        animation={`${sparkGrowAnimation} 1s ease-in-out forwards 0.6s`}
      />
    </Box>
  );
};

export default SpinnerLoader;
