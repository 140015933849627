import { Box } from '@chakra-ui/react';

import AuthHome from 'components/home/AuthHome';
import GuestHome from 'components/home/GuestHome';

import { useAppSelector } from 'hooks/app';

const HomePage = () => {
  const isAuthicated = useAppSelector((store) => store.auth.isAuthicated);

  return <Box>{isAuthicated ? <AuthHome /> : <GuestHome />}</Box>;
};

export default HomePage;
