import { Box, Flex } from '@chakra-ui/react';
import { Card } from 'models';

import CutCard from 'components/builder/CutCard';

interface SquadCutCardsProps {
  cards: Card[];
}

const SquadCutCards = ({ cards }: SquadCutCardsProps) => {
  return (
    <Box pos="relative" mt="32px" zIndex="10">
      <Flex justify="center">
        {cards
          .filter((card) => card.status === 'cut')
          .map((card) => (
            <CutCard card={card} key={card.id} />
          ))}
      </Flex>
    </Box>
  );
};

export default SquadCutCards;
