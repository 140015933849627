import { CSSProperties, FC, useEffect, useRef, useState } from 'react';

import { Box, BoxProps, Flex, Text } from '@chakra-ui/react';
import { Card } from 'models';
import unidecode from 'unidecode';

import usePlayer from 'hooks/usePlayer';

const aspectRatio = 220 / 330;

type Props = BoxProps & {
  card: Card;
  width?: string;
  detailed?: boolean;
  styles?: {
    image?: CSSProperties;
    info?: CSSProperties;
    firstName?: CSSProperties;
    lastName?: CSSProperties;
  };
};

const PlayerCard: FC<Props> = ({ card, width, styles, detailed, ...props }) => {
  const [calcWidth, setCalcWidth] = useState(width ?? 'auto');
  const [ascpectRatio, setAspectRatio] = useState(width ? parseInt(width) / 220 : 1);
  const [isOriginalImageUploaded, setIsOriginalImageUploaded] = useState(false);
  const container = useRef<HTMLDivElement>();

  const player = usePlayer(card);

  useEffect(() => {
    if (width) return;
    if (container.current && player) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          const newHeight = entry.contentRect.height;
          const newWidth = aspectRatio * newHeight;
          setAspectRatio(newWidth / 200);
          setCalcWidth(`${newWidth}px`);
        }
      });

      resizeObserver.observe(container.current);
    }
  }, [player, width, card]);

  useEffect(() => {
    setIsOriginalImageUploaded(false);
    if (!player) return;
    const img = new Image();
    img.src = player?.headshot;
    img.onload = () => {
      setIsOriginalImageUploaded(true);
    };
  }, [player]);

  return (
    <Box
      pos="relative"
      p={`${8 * ascpectRatio}px`}
      bg="white"
      w={calcWidth}
      borderRadius={`${16 * ascpectRatio}px`}
      ref={container}
      boxShadow={`0px ${4 * ascpectRatio}px ${32 * ascpectRatio}px 0px #00000080`}
      {...props}
    >
      <Box
        style={styles?.image}
        backgroundPosition="center center"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundImage={`url(${isOriginalImageUploaded ? player?.headshot : player?.headshotThumbnails?.small})`}
        height={'70%'}
        borderRadius={`${16 * ascpectRatio}px`}
      />

      <Box textAlign="center" pt={`${8 * ascpectRatio}px`} style={styles?.info}>
        {detailed ? (
          <>
            <Text
              variant="firstName"
              fontSize={`${16 * ascpectRatio}px`}
              textTransform="uppercase"
              textColor="inherit"
              color="#9B9292"
              style={styles?.firstName}
            >
              {unidecode(player?.firstName || '')}
            </Text>
            <Text
              variant="lastName"
              fontSize={`${64 * ascpectRatio}px`}
              style={styles?.lastName}
              textTransform="uppercase"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {unidecode(player?.lastName || '')}
            </Text>
          </>
        ) : (
          <Text
            variant="firstName"
            fontSize={`${16 * ascpectRatio}px`}
            textTransform="uppercase"
            textColor="inherit"
            color="#9B9292"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            style={styles?.firstName}
          >
            {unidecode(player?.lastName || '')}
          </Text>
        )}
      </Box>

      <Flex
        align="center"
        justify="center"
        pos="absolute"
        textTransform="uppercase"
        top={`${10 * ascpectRatio}px`}
        left={`${10 * ascpectRatio}px`}
        fontSize={`${14 * ascpectRatio}px`}
        fontWeight="500"
        borderRadius="50%"
        width={`${28 * ascpectRatio}px`}
        height={`${28 * ascpectRatio}px`}
        fontFamily="JetBrains Mono"
        color="#ffffff"
        bg="#141414"
      >
        {player?.primaryPosition}
      </Flex>

      <Flex
        align="center"
        justify="center"
        pos="absolute"
        top={`${10 * ascpectRatio}px`}
        right={`${10 * ascpectRatio}px`}
        fontSize={`${14 * ascpectRatio}px`}
        width={`${28 * ascpectRatio}px`}
        height={`${28 * ascpectRatio}px`}
        backgroundImage={player && player.currentTeamLogo ? `url(${player.currentTeamLogo})` : ''}
        backgroundSize="contain"
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
      ></Flex>
    </Box>
  );
};

export default PlayerCard;
