import { FC } from 'react';

import { Box, BoxProps, keyframes } from '@chakra-ui/react';
import { Card } from 'models';

import usePlayer from 'hooks/usePlayer';

type CardPlaceholderProps = {
  card?: Card;
  showAnimation?: boolean;
} & BoxProps;

const onLoadAnimation = keyframes`
  0% {
    scale: 1;
    opacity: 1;
  }
  99% {
    scale: 1.5;
    opacity: 0.3;
  }
  100% {
    display: none;
  }
`;

const CardPlaceholder: FC<CardPlaceholderProps> = ({ card, showAnimation, ...props }) => {
  const player = usePlayer(card ?? null);
  const headshot = player?.headshotThumbnails?.small ?? player?.headshotThumbnails?.medium ?? player?.headshot;

  return (
    <Box
      w="60px"
      h="60px"
      backgroundColor={card ? 'white' : '#343434'}
      borderRadius="14px"
      backgroundImage={card ? `url(${headshot})` : ''}
      backgroundSize="cover"
      backgroundPosition="center center"
      position="relative"
      {...props}
    >
      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        opacity="0"
        backgroundColor="rgba(255, 255, 255, 0.5)"
        borderRadius="14px"
        animation={headshot && showAnimation ? `${onLoadAnimation} 0.3s ease-in-out forwards` : ''}
      />
    </Box>
  );
};

export default CardPlaceholder;
