import { Card } from 'models';

import { useGetPlayerByIDQuery } from 'api/contest';

const usePlayer = (card: Card | null) => {
  const { data } = useGetPlayerByIDQuery(card?.playerSrId ?? '', {
    skip: !card,
  });
  return card ? data : null;
};

export default usePlayer;
