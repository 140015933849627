import { FC } from 'react';

import { Box } from '@chakra-ui/react';
import { BoxProps, keyframes } from '@chakra-ui/react';

import { ReactComponent as BgIcon } from 'icons/booster-bg.svg';
import { ReactComponent as NoneIcon } from 'icons/none.svg';
import { ReactComponent as SparkIcon } from 'icons/spark.svg';

interface BoosterPlaceholderProps extends BoxProps {
  isDisabled?: boolean;
}

const blinkAnimation = keyframes`
  20%, 100% { 
    
     transform: translateX(120px) translateY(120px) rotate(25deg);
  }
  0% {
   transform: translateX(-80px) translateY(-80px) rotate(25deg);
  }

  10% {
    rotate(35deg);
  }
`;

const blinkShineAnimation = keyframes`
  20%, 100% { 
    
     transform: translateX(220px) translateY(220px) rotate(25deg);
  }
  0% {
   transform: translateX(-180px) translateY(-180px) rotate(25deg);
  }

  10% {
    rotate(35deg);
  }
`;

const textShadowAnimation = keyframes`
  0% {
    text-shadow: 0 0 2px rgba(255, 255, 255, 0.1);
  }
  50% {
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
  }
  100% {
    text-shadow: 0 0 2px rgba(255, 255, 255, 0.1);
  }
`;

const BoosterPlaceholder: FC<BoosterPlaceholderProps> = ({ isDisabled }) => {
  return (
    <>
      {!isDisabled && (
        <>
          <Box
            position="absolute"
            top="-50%"
            left="0"
            width="40px"
            height="200%"
            backgroundColor="rgba(255, 255, 255, 0.3)"
            animation={`${blinkAnimation} 4s infinite ease-in-out`}
          />
          <Box
            position="absolute"
            top="-50%"
            left="0"
            width="10px"
            height="200%"
            filter={`blur(60px)`}
            backgroundColor="rgba(255, 255, 255, 0.3)"
            animation={`${blinkShineAnimation} 4s infinite ease-in-out`}
          />
        </>
      )}
      <Box
        top="50%"
        left="50%"
        pos="absolute"
        transform="translate(-50%, -50%)"
        display="flex"
        flexDir="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          as={isDisabled ? NoneIcon : SparkIcon}
          style={{
            width: '32px',
            height: '32px',
            marginBottom: '8px',
          }}
        />
        <Box
          fontSize="16px"
          fontWeight="800"
          lineHeight="16px"
          textAlign="center"
          animation={`${textShadowAnimation} 4s infinite linear`}
        >
          {isDisabled ? 'no' : 'apply'}
          <br />
          booster
        </Box>
      </Box>
    </>
  );
};

export default BoosterPlaceholder;
